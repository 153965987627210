import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import _, { split } from "lodash";

import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import { buttonColors } from "../../../../utils/enum";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import IncrementDecrementTextboxNormal from "../../../../components/common/IncrementDecrementTextbox/IncrementDecrementTextboxNoraml";
import CardCommon from "../../../../components/card/CardCommon";

export interface StockLevelModalProps {
  isOpenStockLevelModal: boolean;
  setIsOpenStockLevelModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleChangeStockLevel: (value: number) => void;
  stockLevel: number;
  handleSaveStockLevel: () => void;
  stockLevelInitial: number;
  handleDecrement: (value: number) => void;
  handleIncrement: (value: number) => void;
  isLoadingToast: boolean;
  stockLevelName: string;
  displaySuffix: string;
  inputMultiplier: any;
  inputSuffix: string;
  setStockLevel: any;
}

const StockLevelModal: React.FC<StockLevelModalProps> = ({
  isOpenStockLevelModal,
  setIsOpenStockLevelModal,
  handleChangeStockLevel,
  stockLevel,
  handleSaveStockLevel,
  stockLevelInitial,
  handleDecrement,
  handleIncrement,
  isLoadingToast,
  stockLevelName,
  displaySuffix,
  inputMultiplier,
  inputSuffix,
  setStockLevel,
}) => {
  const [purchaseUnit, setPurchaseUnit] = useState(0); //kg
  const [baseUnit, setBaseUnit] = useState(0); // gram
  const [purchaseBaseOneUnit, setPurchaseBaseOneUnit] = useState(0);
  const [purchaseBaseTwoUnit, setPurchaseBaseTwoUnit] = useState(0);

  useEffect(() => {
    setPurchaseUnit(stockLevel / inputMultiplier);
    setBaseUnit(stockLevel);
    setStockLevel();
    setPurchaseBaseOneUnit(
      parseFloat((stockLevel / inputMultiplier)?.toString()?.split(".")[0]) ||
        0,
    );
    setPurchaseBaseTwoUnit(stockLevel % inputMultiplier || 0);
  }, []);

  const handleIncrementPurchaseBaseTwoUnit = () => {
    if (purchaseBaseOneUnit >= 0) {
      if (purchaseBaseTwoUnit + 1 < inputMultiplier) {
        setPurchaseUnit(
          (purchaseBaseOneUnit * inputMultiplier + (purchaseBaseTwoUnit + 1)) /
            inputMultiplier,
        );
        setBaseUnit(
          purchaseBaseOneUnit * inputMultiplier + (purchaseBaseTwoUnit + 1),
        );
        setStockLevel(
          purchaseBaseOneUnit * inputMultiplier + (purchaseBaseTwoUnit + 1),
        );
        setPurchaseBaseOneUnit(
          parseFloat(
            (
              (purchaseBaseOneUnit * inputMultiplier +
                (purchaseBaseTwoUnit + 1)) /
              inputMultiplier
            )
              ?.toString()
              ?.split(".")[0],
          ) || 0,
        );
        setPurchaseBaseTwoUnit(purchaseBaseTwoUnit + 1);
      }
    } else {
      if (purchaseBaseTwoUnit + 1 < inputMultiplier) {
        setPurchaseUnit(
          (purchaseBaseOneUnit * inputMultiplier - (purchaseBaseTwoUnit + 1)) /
            inputMultiplier,
        );
        setBaseUnit(
          purchaseBaseOneUnit * inputMultiplier - (purchaseBaseTwoUnit + 1),
        );
        setStockLevel(
          purchaseBaseOneUnit * inputMultiplier - (purchaseBaseTwoUnit + 1),
        );
        setPurchaseBaseOneUnit(
          parseFloat(
            (
              (purchaseBaseOneUnit * inputMultiplier -
                (purchaseBaseTwoUnit + 1)) /
              inputMultiplier
            )
              ?.toString()
              ?.split(".")[0],
          ) || 0,
        );
        setPurchaseBaseTwoUnit(
          parseFloat((purchaseBaseTwoUnit + 1)?.toString()?.replace("-", "")),
        );
      }
    }
  };

  const handleDecrementPurchaseBaseTwoUnit = () => {
    if (parseFloat(parseFloat(purchaseBaseOneUnit.toString()).toFixed(0)) > 0) {
      if (purchaseBaseTwoUnit - 1 >= 0) {
        setPurchaseUnit(
          (purchaseBaseOneUnit * inputMultiplier + (purchaseBaseTwoUnit - 1)) /
            inputMultiplier,
        );
        setBaseUnit(
          purchaseBaseOneUnit * inputMultiplier + (purchaseBaseTwoUnit - 1),
        );
        setStockLevel(
          purchaseBaseOneUnit * inputMultiplier + (purchaseBaseTwoUnit - 1),
        );
        setPurchaseBaseOneUnit(
          parseFloat(
            (
              (purchaseBaseOneUnit * inputMultiplier +
                (purchaseBaseTwoUnit - 1)) /
              inputMultiplier
            )
              ?.toString()
              ?.split(".")[0],
          ) || 0,
        );
        setPurchaseBaseTwoUnit(
          parseFloat((purchaseBaseTwoUnit - 1)?.toFixed(3)?.replace("-", "")),
        );
      }
    } else {
      if (purchaseBaseTwoUnit - 1 >= 0) {
        setPurchaseUnit(
          (purchaseBaseOneUnit * inputMultiplier - (purchaseBaseTwoUnit - 1)) /
            inputMultiplier,
        );
        setBaseUnit(
          purchaseBaseOneUnit * inputMultiplier - (purchaseBaseTwoUnit - 1),
        );
        setStockLevel(
          purchaseBaseOneUnit * inputMultiplier - (purchaseBaseTwoUnit - 1),
        );
        setPurchaseBaseOneUnit(
          parseFloat(
            (
              (purchaseBaseOneUnit * inputMultiplier -
                (purchaseBaseTwoUnit - 1)) /
              inputMultiplier
            )
              ?.toString()
              ?.split(".")[0],
          ) || 0,
        );
        setPurchaseBaseTwoUnit(
          parseFloat((purchaseBaseTwoUnit - 1)?.toFixed(3)?.replace("-", "")),
        );
      }
    }
  };

  const handlePurchaseBaseTwoUnitPartChange = (value: any) => {
    const data = parseFloat(value?.toString()?.replace(".", ""));
    if (data >= 0 && data < inputMultiplier) {
      setPurchaseBaseTwoUnit(parseFloat(data?.toFixed(3)?.replace("-", "")));
      if (purchaseBaseOneUnit >= 0) {
        setPurchaseUnit(
          (purchaseBaseOneUnit * inputMultiplier + data) / inputMultiplier,
        );
        setBaseUnit(purchaseBaseOneUnit * inputMultiplier + data);
        setStockLevel(purchaseBaseOneUnit * inputMultiplier + data);
        setPurchaseBaseOneUnit(
          parseFloat(
            ((purchaseBaseOneUnit * inputMultiplier + data) / inputMultiplier)
              ?.toString()
              ?.split(".")[0],
          ) || 0,
        );
      } else {
        setPurchaseUnit(
          (purchaseBaseOneUnit * inputMultiplier - data) / inputMultiplier,
        );
        setBaseUnit(purchaseBaseOneUnit * inputMultiplier - data);
        setStockLevel(purchaseBaseOneUnit * inputMultiplier - data);
        setPurchaseBaseOneUnit(
          parseFloat(
            ((purchaseBaseOneUnit * inputMultiplier - data) / inputMultiplier)
              ?.toString()
              ?.split(".")[0],
          ) || 0,
        );
      }
    }
  };

  const handleIncrementPurchaseBaseOneUnit = () => {
    if (purchaseBaseOneUnit + 1 >= 0) {
      setPurchaseUnit(
        ((purchaseBaseOneUnit + 1) * inputMultiplier + purchaseBaseTwoUnit) /
          inputMultiplier,
      );
      setBaseUnit(
        (purchaseBaseOneUnit + 1) * inputMultiplier + purchaseBaseTwoUnit,
      );
      setStockLevel(
        (purchaseBaseOneUnit + 1) * inputMultiplier + purchaseBaseTwoUnit,
      );
      setPurchaseBaseOneUnit(purchaseBaseOneUnit + 1);
      setPurchaseBaseTwoUnit(
        parseFloat(
          (
            ((purchaseBaseOneUnit + 1) * inputMultiplier +
              purchaseBaseTwoUnit) %
            inputMultiplier
          )
            ?.toFixed(3)
            ?.replace("-", ""),
        ) || 0,
      );
    } else {
      setPurchaseUnit(
        ((purchaseBaseOneUnit + 1) * inputMultiplier -
          Math.abs(purchaseBaseTwoUnit)) /
          inputMultiplier,
      );
      setBaseUnit(
        (purchaseBaseOneUnit + 1) * inputMultiplier -
          Math.abs(purchaseBaseTwoUnit),
      );
      setStockLevel(
        (purchaseBaseOneUnit + 1) * inputMultiplier -
          Math.abs(purchaseBaseTwoUnit),
      );
      setPurchaseBaseOneUnit(purchaseBaseOneUnit + 1);
      setPurchaseBaseTwoUnit(
        parseFloat(
          (
            ((purchaseBaseOneUnit + 1) * inputMultiplier -
              Math.abs(purchaseBaseTwoUnit)) %
            inputMultiplier
          )
            ?.toFixed(3)
            ?.replace("-", ""),
        ) || 0,
      );
    }
  };

  const handleDecrementPurchaseBaseOneUnit = () => {
    if (purchaseBaseOneUnit - 1 >= 0) {
      setPurchaseUnit(
        ((purchaseBaseOneUnit - 1) * inputMultiplier + purchaseBaseTwoUnit) /
          inputMultiplier,
      );
      setBaseUnit(
        (purchaseBaseOneUnit - 1) * inputMultiplier + purchaseBaseTwoUnit,
      );
      setStockLevel(
        (purchaseBaseOneUnit - 1) * inputMultiplier + purchaseBaseTwoUnit,
      );
      setPurchaseBaseOneUnit(purchaseBaseOneUnit - 1);
      setPurchaseBaseTwoUnit(
        parseFloat(
          (
            ((purchaseBaseOneUnit - 1) * inputMultiplier +
              purchaseBaseTwoUnit) %
            inputMultiplier
          )
            ?.toFixed(3)
            ?.replace("-", ""),
        ) || 0,
      );
    } else {
      setPurchaseUnit(
        ((purchaseBaseOneUnit - 1) * inputMultiplier -
          Math.abs(purchaseBaseTwoUnit)) /
          inputMultiplier,
      );
      setBaseUnit(
        (purchaseBaseOneUnit - 1) * inputMultiplier -
          Math.abs(purchaseBaseTwoUnit),
      );
      setStockLevel(
        (purchaseBaseOneUnit - 1) * inputMultiplier -
          Math.abs(purchaseBaseTwoUnit),
      );
      setPurchaseBaseOneUnit(purchaseBaseOneUnit - 1);
      setPurchaseBaseTwoUnit(
        parseFloat(
          (
            ((purchaseBaseOneUnit - 1) * inputMultiplier -
              Math.abs(purchaseBaseTwoUnit)) %
            inputMultiplier
          )
            ?.toFixed(3)
            ?.replace("-", ""),
        ) || 0,
      );
    }
  };

  const handlePurchaseBaseOneUnitPartChange = (value: any) => {
    const data = parseFloat(value?.toString()?.replace(".", ""));
    if (data >= 0) {
      setPurchaseUnit(
        (data * inputMultiplier + purchaseBaseTwoUnit) / inputMultiplier,
      );
      setBaseUnit(data * inputMultiplier + purchaseBaseTwoUnit);
      setStockLevel();
      setPurchaseBaseOneUnit(data);
      setPurchaseBaseTwoUnit(
        parseFloat(
          ((data * inputMultiplier + purchaseBaseTwoUnit) % inputMultiplier)
            ?.toFixed(3)
            ?.replace("-", ""),
        ) || 0,
      );
    } else {
      setPurchaseUnit(
        (data * inputMultiplier - Math.abs(purchaseBaseTwoUnit)) /
          inputMultiplier,
      );
      setBaseUnit(data * inputMultiplier - Math.abs(purchaseBaseTwoUnit));
      setStockLevel(data * inputMultiplier - Math.abs(purchaseBaseTwoUnit));
      setPurchaseBaseOneUnit(data);
      setPurchaseBaseTwoUnit(
        parseFloat(
          (
            (data * inputMultiplier - Math.abs(purchaseBaseTwoUnit)) %
            inputMultiplier
          )
            .toFixed(3)
            ?.replace("-", ""),
        ) || 0,
      );
    }
  };

  const handlePurchaseUnitPartChange = (value: number) => {
    if (
      !value?.toString()?.split(".")[1] ||
      value?.toString()?.split(".")[1]?.length <= 3
    ) {
      setPurchaseUnit(value);
      setBaseUnit(value * inputMultiplier);
      setStockLevel(value * inputMultiplier);
      setPurchaseBaseOneUnit(
        parseFloat(
          ((value * inputMultiplier) / inputMultiplier)
            ?.toString()
            ?.split(".")[0],
        ) || 0,
      );
      setPurchaseBaseTwoUnit(
        parseFloat(
          ((value * inputMultiplier) % inputMultiplier)
            ?.toFixed(3)
            ?.replace("-", ""),
        ) || 0,
      );
    }
  };

  const handleBaseUnitPartChange = (value: number) => {
    const data = parseFloat(value?.toString()?.replace(".", ""));
    setPurchaseUnit(data / inputMultiplier);
    setBaseUnit(data);
    setStockLevel(data);
    setPurchaseBaseOneUnit(
      parseFloat((data / inputMultiplier)?.toString()?.split(".")[0]) || 0,
    );
    setPurchaseBaseTwoUnit(
      parseFloat((data % inputMultiplier)?.toFixed(3)?.replace("-", "")) || 0,
    );
  };

  return (
    <DialogCommonDefault
      open={isOpenStockLevelModal}
      setOpen={setIsOpenStockLevelModal}
      isNeedFixedHeight={false}
      backgroundColor="entity_background"
      maxWidth="xs"
    >
      <DialogTitle>{`Stock Level ${stockLevelName}`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6} style={{ paddingRight: "4px" }}>
            <IncrementDecrementTextboxNormal
              handleValue={(e: any) =>
                handlePurchaseBaseOneUnitPartChange(Number(e.target.value))
              }
              value={Number(
                parseFloat(purchaseBaseOneUnit.toString())?.toString(),
              )}
              handleIncrement={() => handleIncrementPurchaseBaseOneUnit()}
              handleDecrement={() => handleDecrementPurchaseBaseOneUnit()}
              isDisable={false}
              displaySuffix={inputSuffix}
            />
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: "4px" }}>
            <IncrementDecrementTextboxNormal
              handleValue={(e: any) =>
                handlePurchaseBaseTwoUnitPartChange(Number(e.target.value))
              }
              value={Number(
                parseFloat(purchaseBaseTwoUnit.toString())?.toFixed(3),
              )}
              handleIncrement={() => handleIncrementPurchaseBaseTwoUnit()}
              handleDecrement={() => handleDecrementPurchaseBaseTwoUnit()}
              isDisable={false}
              displaySuffix={displaySuffix}
            />
          </Grid>

          {/* Spacer */}
          <Grid item xs={12}>
            <div style={{ height: "4px" }}></div>
          </Grid>

          <Grid item xs={6}>
            <CardCommon
              backgroundColor={"entity_background"}
              style={{
                height: "120px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <Typography
                  variant="body1"
                  align="center"
                  style={{ paddingBottom: "16px" }}
                >
                  Purchase Unit
                </Typography>
                <Typography
                  variant="h5"
                  align="center"
                  style={{ fontWeight: "bold" }}
                >
                  {Number(parseFloat(purchaseUnit.toString())?.toFixed(3))}
                </Typography>
                <Typography variant="body1" align="center">
                  {inputSuffix}
                </Typography>
              </div>
            </CardCommon>
          </Grid>
          <Grid item xs={6}>
          <CardCommon
              backgroundColor={"entity_background"}
              style={{
                height: "120px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <Typography
                  variant="body1"
                  align="center"
                  style={{ paddingBottom: "16px" }}
                >
                  Base Unit
                </Typography>
                <Typography
                  variant="h5"
                  align="center"
                  style={{ fontWeight: "bold" }}
                >
                  {Number(
                  parseFloat(baseUnit.toString())?.toFixed(3),
                )}
                </Typography>
                <Typography variant="body1" align="center">
                  {displaySuffix}
                </Typography>
              </div>
            </CardCommon>

          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginBottom: "12px" }}>
        <ButtonCommon
          onClick={() => setIsOpenStockLevelModal(false)}
          disabled={isLoadingToast}
          variant="contained"
          style={{ fontSize: 11, width: "120px", marginRight: "12px" }}
          color={buttonColors.CANCEL_BUTTON_COLOR}
        >
          Back
        </ButtonCommon>

        <ButtonCommon
          disabled={stockLevelInitial === stockLevel || isLoadingToast}
          onClick={handleSaveStockLevel}
          variant="contained"
          style={{ fontSize: 11, width: "120px", marginRight: "14px" }}
          color={buttonColors.CREATE_BUTTON_COLOR}
        >
          Done
        </ButtonCommon>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default StockLevelModal;
